import React, { useState } from "react"
import { Dialog, DialogPanel } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import AdaptLogo from "../images/adaptLogo.png"

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-black">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-center p-6 lg:px-8"
      >
        <div className="flex">
          <a href="https://adaptplumbingdrains.com.au" className="-m-1.5 p-1.5">
            <span className="sr-only">Adapt Plumbing & Drainage</span>
            <img alt="Adapt Plumbing & Drainage Logo" src={AdaptLogo} className="h-24 w-auto" />
          </a>
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-center">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Adapt Plumbing & Drainage</span>
              <img alt="" src={AdaptLogo} className="h-8 w-auto" />
            </a>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}
