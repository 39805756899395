import React, { useState } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"

export default function Banner() {
  const [websiteNewShow, setWebsiteNewShow] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("new_website_banner") == null
        ? false
        : true
      : null
  )

  return (
    <>
      {/*
        Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
        your content from being obscured when the user scrolls to the bottom of the page.
      */}
      {!websiteNewShow ? (
        <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
          <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-secondary px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <p className="text-sm leading-6 text-white">
              <a href="#">
                <strong className="font-semibold">
                  A New Website is Coming Soon!
                </strong>
                <svg
                  viewBox="0 0 2 2"
                  aria-hidden="true"
                  className="mx-2 inline h-0.5 w-0.5 fill-current"
                >
                  <circle r={1} cx={1} cy={1} />
                </svg>
                We're busy crafting a brand-new online experience to better
                serve you.
              </a>
            </p>
            <button
              onClick={() => {
                localStorage.setItem("new_website_banner", true)
                setWebsiteNewShow(true)
              }}
              type="button"
              className="-m-1.5 flex-none p-1.5"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
